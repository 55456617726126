import React, { Component } from "react";

export default class Projects extends Component {
    render() {
        return (
            <>
                <ul>
                    <li><a href="https://github.com/msteele96/onx-clone">OnX Clone</a></li>
                    <li><a href="https://github.com/msteele96/js-spa">JS Snake</a></li>
                </ul>
            </>
        )
    }
}